import axios from 'axios'
import { MessageBox, Message } from 'element-ui'

// 创建一个axios实例
const service = axios.create({
  headers: {
    'content-type': 'application/json;charset=UTF-8'
    // 'Access-control-Allow-Methods': 'POST,GET,OPTIONS,DELETE'
  },
  baseURL: `https://s.zeersheng.com`,
  // baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: false,
  timeout: 5000
})

// 添加请求拦截器
service.interceptors.request.use(config => {
  // 在发送请求之前做某事，比如说 设置token
  // config.headers['token'] = 'token';
  // config.headers['X-Token'] = 'xxxxxxxxxxxxxxxxxxx';
  return config;
}, error => {
  // 请求错误时做些事
  return Promise.reject(error);
});

// 添加响应拦截器
service.interceptors.response.use(response => {
  const res = response.data;
  // 如果返回的状态不是200 就主动报错
  if (res.code !== 200) {
    Message({
      message: res.msg || 'Error',
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(res.msg || 'error')
  }
  return response;
}, error => {
  return Promise.reject(error.response.msg || 'error'); // 返回接口返回的错误信息
})

export default service
