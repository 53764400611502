import request from '@/utils/request'

const path = `/system`

const scoreAPI = {
  // 获取导航
  getScoreNav (params) {
    return request({
      url: path + '/search/list',
      method: 'GET',
      params
    })
  }

}

export default scoreAPI;
