import majorAPI from '@/api/major' // 按专业找老师
import majorChooseMajorAPI from '@/api/majorChooseMajor' // 按专业找老师
import majorChooseSchoolAPI from '@/api/majorChooseSchool' // 按专业找老师
import settledAPI from '@/api/settled' // 教师入驻
import scoreAPI from '@/api/score'
import postgraduateAPI from '@/api/postgraduate'

const API = Object.assign(majorAPI, majorChooseMajorAPI, majorChooseSchoolAPI, settledAPI, scoreAPI, postgraduateAPI)

export default API
