import request from '@/utils/request'

const path = `/system`

const majorChooseMajorAPI = {
  // 获取专业分类列表
  getSpecialityList (params) {
    return request({
      url: path + '/speciality/list',
      method: 'GET',
      params
    })
  },
  // 获取专业分类列表 查专业名
  getSpecialityListByName (params) {
    return request({
      url: path + '/teacherCenter/searchTeacherList',
      method: 'GET',
      params
    })
  },
  // 通过id获取老师
  getTeacherInfo (params) {
    return request({
      url: path + '/teacherCenter/getTeacherInfo',
      method: 'GET',
      params
    })
  }
}

export default majorChooseMajorAPI
