export default {
  // 获取教师标签
  getTeacherTags: function(teacherInfo) {
    let tagArr = [];
    if (teacherInfo === undefined || teacherInfo == null) {
      return tagArr;
    }
    let teacherType = teacherInfo.teacherType;
    if (teacherType === '1') {
      if (teacherInfo.specialityList && teacherInfo.specialityList.length > 0) {
        tagArr.push('专业咨询');
      }
      if (teacherInfo.schoolList && teacherInfo.schoolList.length > 0) {
        tagArr.push('学校咨询');
      }
    } else {
      if (teacherType === '3') {
        if (teacherInfo.collegeId) {
          tagArr.push(teacherInfo.collegeId);
        }
        if (teacherInfo.consultType) {
          let arr = teacherInfo.consultType.split(',');
          tagArr.push(arr[2]);
        }
        if (teacherInfo.master) {
          tagArr.push(teacherInfo.master);
        }
        if (teacherInfo.serviceLabel) {
          let consultArr = JSON.parse(teacherInfo.serviceLabel);
          consultArr.forEach(item => {
            tagArr.push(item.text);
          })
        }
      }

      if (teacherInfo.collegeProvinceName) {
        tagArr.push(teacherInfo.collegeProvinceName + '高考');
      }

      if (teacherInfo.goodSubjects) {
        let goodSubjectsArr = teacherInfo.goodSubjects.split(',');
        goodSubjectsArr.forEach((item) => {
          tagArr.push(item);
        });
      }
    }
    if (teacherInfo.zerConsultScopeList && teacherInfo.zerConsultScopeList.length > 0) {
      teacherInfo.zerConsultScopeList.forEach((item) => {
        tagArr.push(item.name);
      });
    }
    if (teacherInfo.zerGuideInfoList && teacherInfo.zerGuideInfoList.length > 0) {
      teacherInfo.zerGuideInfoList.forEach((item) => {
        tagArr.push(item.name);
      });
    }
    return tagArr;
  },

  // 获取教师学校
  getTeacherSchool: function (teacherInfo) {
    let schoolArr = [];
    if (teacherInfo.schoolList && teacherInfo.schoolList.length > 0) {
      teacherInfo.schoolList.forEach(school => {
        let collegeArr = [];
        let allCollege = null;
        if (school.subjectTypeList) {
          allCollege = {
            name: '所有学院',
            subjectTypeList: school.subjectTypeList
          };
          collegeArr.push(allCollege)
        }

        if (school.childList) {
          school.childList.forEach(college => {
            if (college.subjectTypeList) {
              let arr = college.subjectTypeList.filter(p => {
                return allCollege == null || allCollege.subjectTypeList.findIndex(t => t.id === p.id) === -1;
              })
              if (arr.length > 0) {
                collegeArr.push({
                  name: college.name,
                  subjectTypeList: arr
                })
              }
            }
          })
        }
        if (collegeArr.length > 0) {
          schoolArr.push({
            name: school.name,
            collegeArr: collegeArr
          })
        }
      });
    }
    return schoolArr
  }
}
