import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import wow from 'wowjs'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import './assets/basic.css'
import './assets/global.less'
import animated from 'animate.css'
import './assets/commit.js'
import commit from './assets/commit.js'

import * as echarts from 'echarts';
// import Echarts from 'vue-echarts'
Vue.prototype.$echarts = echarts // 这一步是为了能在全局直接使用

import china from './assets/china.json'// 引入json文件
echarts.registerMap('china', china) // 在echarts中注册使用这个文件
import 'swiper/dist/css/swiper.min.css'
import 'swiper/dist/js/swiper.min'

import apis from '@/api/index' // api

// 挂载Vue原型上的方法，统一用$命名
Vue.prototype.$apis = apis // api请求
Vue.prototype.commit = commit

Vue.use(VueAwesomeSwiper)
Vue.use(animated)
Vue.config.productionTip = false
Vue.prototype.$wow = wow
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 页面跳转显示在顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
})
