<template>
  <div id="app">
    <div
      v-if="!$route.meta.hideNav"
      id="nav"
      class="globalnav sflex sflex-ac sflex-jsp"
      :class="{ headActive: isUp, headInactive: isDown }"
    >
      <img class="logo nav-left-logo" src="./assets/images/logo.jpg" alt="" />
      <div class="nav sflex sflex-ac">
        <router-link to="/">首页</router-link>
        <router-link to="/major">选专业</router-link>
        <router-link to="/score">提分数</router-link>
        <!-- <router-link to="/volunteer">填志愿</router-link> -->
        <router-link to="/postgraduate">助考研</router-link>
        <!-- <router-link to="/news">教育资讯</router-link>
        <router-link to="/education">教育号</router-link> -->
        <router-link to="/about">关于我们</router-link>
        <router-link to="/settled">教师入驻</router-link>
        <div class="nav-phone-box sflex sflex-ac">
          <img src="./assets/images/icon-phone.png" class="nav-icon" alt="" />
          <div class="num">400-0608-985</div>
        </div>
      </div>
    </div>
    <div class="mobile-nav" :class="{ headActive: isUp, headInactive: isDown }">
      <div class="mobile-nav-box sflex sflex-ac sflex-jsp">
        <img class="logo" src="./assets/images/logo1.png" alt="" />
        <div
          class="m-nav-btn"
          :class="{ active: isShow }"
          @click="togglerNav()"
        >
          <span></span>
        </div>
      </div>
      <div class="m-nav-content sflex sflex-jc" :class="{ active: isShow }">
        <ul class="m-nav-list">
          <router-link to="/">首页</router-link>
          <router-link to="/major">选专业</router-link>
          <router-link to="/score">提分数</router-link>
          <!-- <router-link to="/volunteer">填志愿</router-link> -->
          <router-link to="/postgraduate">助考研</router-link>
          <!-- <router-link to="/news">教育资讯</router-link>
          <router-link to="/education">教育号</router-link> -->
          <router-link to="/about">关于我们</router-link>
          <router-link to="/settled">教师入驻</router-link>
          <div class="nav-phone-box sflex sflex-ac sflex-jc">
            <img src="./assets/images/icon-phone.png" class="nav-icon" alt="" />
            <div class="num">400-0608-985</div>
          </div>
        </ul>
      </div>
    </div>
    <router-view />
    <bottom />
  </div>
</template>

<script>
import bottom from './components/footer.vue'

export default {
  components: {
    bottom,
  },
  data() {
    return {
      isShow: false,
      i: 0,
      isUp: false,
      isDown: false,
    }
  },
  computed: {},
  watch: {
    $route: 'closeMobileNav',
  },
  mounted() {
    // 监听（绑定）滚轮 滚动事件
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    togglerNav() {
      this.isShow = !this.isShow
    },
    closeMobileNav() {
      this.isShow = false
    },

    handleScroll() {
      // 页面滚动距顶部距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop
      let scroll = scrollTop - this.i
      this.i = scrollTop
      if (this.i === 0) {
        this.isUp = false
        this.isShow = false
        this.isDown = false
      } else if (scroll < 0) {
        this.isUp = true
        this.isShow = false
        this.isDown = false
      } else {
        this.isUp = false
        this.isShow = false
        this.isDown = true
      }
    },
  },
}
</script>

<style lang="less">
// body {
//   overflow-y: auto !important;
// }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-y: auto !important;
}
.headActive {
  position: fixed;
  top: 0;
  opacity: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}
.headInactive {
  position: fixed;
  top: 0;
  opacity: 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -ms-transform: translateY(-100%);
}

#nav {
  // position: fixed;
  // top: 0;
  width: 100vw;
  z-index: 999;
  background: rgba(255, 255, 255);
  opacity: 1;
  transition: 0.5s;
  a {
    display: block;
    font-size: 0.9rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    padding: 1.05rem 1rem 0.95rem;
    height: 100%;
    color: #606266;
    -webkit-background-clip: text;

    &.router-link-exact-active {
      position: relative;
      &::after {
        position: absolute;
        bottom: 0.4rem;
        left: calc((100% - 1.6rem) / 2);
        width: 1.6rem;
        height: 0.35rem;
        background: linear-gradient(86deg, #455ef8, #5b76e0);
        border-radius: 0rem;
        content: '';
        border-radius: 5px;
      }
    }

    // &:hover {
    //   color: #4564f8;
    // }
  }

  a:last-child {
    margin: 0 0 0 0.3rem;
  }
}
.globalnav {
  padding: 0.45rem 13.6vw 0.1rem 14vw;
  display: block;
  .nav-left-logo {
    width: 15rem;
    // height: 3.25rem;
    background: #ffffff;
    border-radius: 0rem 0rem 0rem 0rem;
    opacity: 1;
  }

  .nav-phone-box {
    .nav-icon {
      width: 1.05rem;
      height: 1.05rem;
      margin: 0 0.65rem 0 1.3rem;
    }
    .num {
      font-size: 0.9rem;
      font-weight: bold;
      color: #fa791c;
    }
  }
}
.mobile-nav {
  // position: fixed;
  // top: 0;
  width: 100%;
  display: none;
  transition: 0.5s;
  z-index: 999;
}

@media only screen and (max-width: 1600px) {
  .globalnav {
    padding: 0.85rem 7vw 0.35rem !important;
    .nav-left-logo {
      width: 13rem;
      // height: 2.5rem;
      background: #ffffff;
      border-radius: 0rem 0rem 0rem 0rem;
      opacity: 1;
    }

    .nav-phone-box {
      .nav-icon {
        width: 0.9rem;
        height: 0.9rem;
        margin: 0 0.65rem 0 0.8rem;
      }
      .num {
        font-size: 0.8rem;
        font-weight: bold;
        color: #fa791c;
      }
    }
  }

  #nav {
    a {
      padding: 1.05rem 0.8rem 0.95rem;
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 1400px) {
  .globalnav {
    padding: 0.85rem 4vw 0.35rem !important;
  }

  #nav {
    a {
      padding: 0.8rem 0.6rem 0.7rem;
      font-size: 0.8rem;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .globalnav {
    padding: 0.85rem 2vw 0.35rem !important;
  }
  #nav {
    a {
      padding: 0.8rem 0.3rem 0.7rem !important;
    }
  }
}
@media only screen and (max-width: 767px) {
  .globalnav {
    display: none !important;
  }
  .mobile-nav {
    display: block;
    .mobile-nav-box .logo {
      width: 12rem;
    }
  }
  .mobile-nav-box {
    // position: fixed;
    // top: 0;
    width: 100%;
    z-index: 999;
    height: 4rem;
    background: linear-gradient(86deg, #455ef8, #5b76e0);
    padding: 0 1rem;
  }
  .m-nav-btn {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    z-index: 1000;
    position: relative;
    display: flex !important;
    align-items: center;
    cursor: pointer;
    padding: 5px;
  }
  .m-nav-btn span {
    background-color: #fff;
    width: 30px;
    height: 3px;
    position: absolute;
    display: flex !important;
    align-content: center;
    justify-content: flex-end;
    transition: all 0.2s ease;
    right: 0;
    border-radius: 5px;
  }
  .m-nav-btn span::before,
  .m-nav-btn span::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
  }
  .m-nav-btn span::before {
    transform: translateY(-0.5rem);
    transition: all 0.3s 0.1s ease;
  }
  .m-nav-btn span::after {
    transform: translateY(0.5rem);
    transition: all 0.3s 0.1s ease;
  }
  .m-nav-btn:hover span::before {
    width: 60%;
  }
  .m-nav-btn:hover span::after {
    width: 80%;
  }
  .m-nav-btn.active span {
    background: rgba(0, 0, 0, -0.2);
  }
  .m-nav-btn.active span::before {
    transform: rotate(45deg);
  }
  .m-nav-btn.active span::after {
    transform: rotate(-45deg);
  }
  .m-nav-btn:hover.active span::before,
  .m-nav-btn:hover.active span::after {
    width: 100%;
  }
  .mobile-nav .m-nav-content {
    position: fixed;
    top: 4rem;
    width: 100%;
    height: 0;
    background: #fff;
    z-index: 999;
    transform: translateX(100%);
    transition: all 0.3s 0.1s ease;
  }
  .mobile-nav .m-nav-content.active {
    // width: 100%;
    height: calc(100vh - 4rem);
    display: flex;
    transform: translateX(0%);
    // transition: all 0.3s 0.1s ease;
  }
  .mobile-nav .m-nav-content .m-nav-list {
    width: 100%;
  }
  .mobile-nav .m-nav-content .m-nav-list .nav-phone-box {
    width: 100%;
    padding: 1rem 0;
  }
  .mobile-nav .m-nav-content .m-nav-list a {
    display: block;
    padding: 1rem 0;
    width: 100vw;
    color: #606266;
    font-size: 0.8rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
  .mobile-nav .m-nav-content .m-nav-list a:last-child {
    border: 0;
  }
}
</style>
