<template>
  <div class="home">
    <div class="map-modal sflex sflex-ac sflex-wrap">
      <!-- 地图 -->
      <div id="regionCharts" class="map_left"></div>
      <!-- 好前途 -->
      <div class="map_center sflex-y">
        <div><img src="../assets/images/map_title.png" /></div>
        <div class="text1_2 ffffffText">
          <span class="yellowText text2_15 textMargin_10">数百</span
          ><span class="yellowText">名</span>大学教师真人实名上线 <br />
          <span class="textBold">为你讲专业、选学校、填志愿</span>
        </div>
        <div class="sflex sflex-ac ffffffText marginTop10">
          <div class="marginRight40">
            <img class="qr-img" src="../assets/images/qr1.png" />
            <span class="qr-txt">微信公众号</span>
          </div>
          <div>
            <img class="qr-img" src="../assets/images/qr4.jpg" />
            <span class="qr-txt">扫一扫立即咨询</span>
          </div>
        </div>
      </div>
      <!-- 小程序图 -->
      <div class="map_right">
        <img src="../assets/images/applet.png" />
      </div>
    </div>

    <div ref="mySwiper" class="swiper-container map-swiper">
      <div class="swiper-wrapper">
        <div v-for="(item, i) in mapContent" :key="i" class="swiper-slide">
          <div class="sflex sflex-ac sflex-jc map-content">
            <div class="swiper-left">
              <!-- <div><img src="" /></div> -->
              <div class="swiper-left-text">
                {{ item.content }}
              </div>
            </div>
            <div class="swiper-center">
              <img :src="item.image1" />
            </div>
            <div class="swiper-right">
              <img :src="item.image2" />
            </div>
          </div>
        </div>

        <!-- <div class="swiper-slide">Slide 2</div>
        <div class="swiper-slide">Slide 3</div> -->
      </div>
      <!-- 如果需要分页器 -->
      <div class="swiper-pagination"></div>

      <!-- 如果需要导航按钮 -->
      <div class="swiper-button-prev"></div>
      <div class="swiper-button-next"></div>

      <!-- 如果需要滚动条 -->
      <!--    <div class="swiper-scrollbar"></div>-->
    </div>

    <compete />
    <choose />
    <teacher />
    <!-- <partner /> -->
  </div>
</template>

<script>
import compete from '../components/compete.vue'
// import partner from '../components/partner.vue'
import teacher from '../components/teacher.vue'

import Swiper from 'swiper'

import choose from '../components/choose.vue'
import mapContent from '../assets/map_label.json'
export default {
  name: 'Home',
  components: {
    // partner,
    compete,
    choose,
    teacher,
  },
  data() {
    return {
      dataList: [
        { name: '北京', value: 200, selected: true, index: 0 },
        { name: '天津', value: 600, selected: false, index: 1 },
        { name: '河北', value: 800, selected: false, index: 2 },
        { name: '山西', value: 300, selected: false, index: 3 },
        { name: '内蒙古', value: 100, selected: false, index: 4 },
        { name: '辽宁', value: 200, selected: false, index: 5 },
        { name: '吉林', value: 200, selected: false, index: 6 },
        { name: '黑龙江', value: 200, selected: false, index: 7 },
        { name: '上海', value: 600, selected: false, index: 8 },
        { name: '江苏', value: 400, selected: false, index: 9 },
        { name: '浙江', value: 800, selected: false, index: 10 },
        { name: '安徽', value: 300, selected: false, index: 11 },
        { name: '福建', value: 600, selected: false, index: 12 },
        { name: '江西', value: 300, selected: false, index: 13 },
        { name: '山东', value: 300, selected: false, index: 14 },
        { name: '河南', value: 200, selected: false, index: 15 },
        { name: '湖北', value: 800, selected: false, index: 16 },
        { name: '湖南', value: 200, selected: false, index: 17 },
        { name: '广东', value: 100, selected: false, index: 18 },
        { name: '广西', value: 800, selected: false, index: 19 },
        { name: '海南', value: 800, selected: false, index: 20 },
        { name: '重庆', value: 100, selected: false, index: 21 },
        { name: '四川', value: 500, selected: false, index: 22 },
        { name: '贵州', value: 600, selected: false, index: 23 },
        { name: '云南', value: 300, selected: false, index: 24 },
        { name: '西藏', value: 100, selected: false, index: 25 },
        { name: '陕西', value: 800, selected: false, index: 26 },
        { name: '甘肃', value: 500, selected: false, index: 27 },
        { name: '青海', value: 300, selected: false, index: 28 },
        { name: '宁夏', value: 500, selected: false, index: 29 },
        { name: '新疆', value: 200, selected: false, index: 30 },
        { name: '台湾', value: 800, selected: false, index: null },
        { name: '香港', value: 500, selected: false, index: null },
        { name: '澳门', value: 500, selected: false, index: null },
        { name: '南海诸岛', selected: false, value: null },
      ],
      publicPath: '../',
      mapContent: mapContent.data,
    }
  },
  created() {
    this.mapContent.forEach((item, i) => {
      this.mapContent[i]['image1'] = require('../assets/images/citySchool' +
        item.image1)
      this.mapContent[i]['image2'] = require('../assets/images/citySchool' +
        item.image2)
    })
  },

  mounted() {
    let that = this
    let swiper = new Swiper('.map-swiper', {
      loop: false,
      autoplay: 6000,
      // 如果需要分页器
      // pagination: '.swiper-pagination',
      // 如果需要前进后退按钮
      nextButton: '.swiper-button-next',
      prevButton: '.swiper-button-prev',
      virtualTranslate: false,
      onTransitionEnd: function (swiper) {
        // console.info('页面跳转到第:',, ' 页')
        that.changeDataList(swiper.activeIndex)
      },

      // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
      // 如果需要自动切换海报
      // autoplay: {
      //   delay: 1000,//时间 毫秒
      //   disableOnInteraction: false,//用户操作之后是否停止自动轮播默认true
      // },
    })
    // this.$refs.mySwiper.swiper.on()
    this.drawregionCharts() // 在页面进入的时候，先请求后端数据再调用这个函数，但由于我这里是写死的假数据，于是就直接调用了
  },
  methods: {
    drawregionCharts() {
      let width = document.documentElement.clientWidth
      let zoom = 1.2
      let top = 80
      // if (width > 1600) {
      //   zoom = 1.2
      //   top = 80
      // }
      // if (width >= 2500) {
      //   zoom = 1.2
      //   top = 80
      // }
      // if (width < 1600 && width > 1500) {
      //   zoom = 1.2
      //   top = 80
      // }
      // if (width < 1500 && width > 1400) {
      //   zoom = 1.85
      //   top = 180
      // }
      // if (width < 1400 && width > 1200) {
      //   zoom = 1.55
      //   top = 130
      // }
      // if (width < 1200 && width > 1000) {
      //   zoom = 1.75
      //   top = 180
      // }
      // if (width < 1000 && width > 768) {
      //   zoom = 2.35
      //   top = 180
      // }
      // if (width < 768) {
      //   zoom = 2.8
      //   top = 150
      // }
      let option = {
        tooltip: {
          trigger: 'item',
          formatter: function (params) {
            return params.name // 自行定义formatter格式
          },
        },
        visualMap: {
          min: 0,
          max: 800,
          left: 'left',
          top: 'bottom',
          text: ['高', '低'], // 取值范围的文字
          inRange: {
            color: ['#e0ffff', '#006edd'], // 取值范围的颜色
          },
          show: false, // 图注
        },
        geo: {
          map: 'china',
          selectedMode: 'single',
          roam: false, // 不开启缩放和平移
          zoom: zoom, // 视角缩放比例
          top: top,
          right: 60,
          show: false,
          label: {
            normal: {
              show: true,
              fontSize: '10',
              color: '#ffffff',
              fontWeight: '800',
            },
            emphasis: {
              textStyle: {
                color: '#fff805',
              },
            },
          },
          itemStyle: {
            normal: {
              borderColor: 'rgba(0, 0, 0, 0.2)',
              areaColor: '#ffffff',
            },
            emphasis: {
              areaColor: '#ffffff', // 鼠标选择区域颜色
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 20,
              borderWidth: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)',
            },
          },
          select: {
            normal: {
              show: true,
              areaColor: '#ffffff',
              borderColor: '#111',
            },
            itemStyle: {
              areaColor: '#ff8c06', // 鼠标选择区域颜色
              show: true,
              fontSize: 16,
            },
          },
        },
        series: [
          {
            name: '信息量',
            type: 'map',
            map: 'china',
            zoom: zoom, // 视角缩放比例
            top: top,
            selectedMode: 'single',
            geoIndex: 1,
            label: {
              show: true,
              normal: {
                show: true,
                color: '#fff',
              },
              emphasis: {
                areaColor: '#000000', // 鼠标选择区域颜色
                show: true,
                fontSize: 16,

                textStyle: {
                  color: '#f1f011',
                  fontWeight: 'bold',
                  fontSize: 18,
                },
              },
            },
            itemStyle: {
              normal: {
                show: true,
                areaColor: '#ffffff',
                borderColor: '#111',
              },
              emphasis: {
                areaColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: '#fc770b' },
                    { offset: 0.8, color: '#ffd604' },
                    { offset: 1, color: '#fff405' },
                  ],
                  global: false,
                },
                show: true,
                fontSize: 16,
              },
            },

            select: {
              label: {
                show: true,
                color: '#f1f011',
                borderColor: '#111',
                fontWeight: 'bold',
                fontSize: 18,
              },
              itemStyle: {
                areaColor: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    { offset: 0, color: '#fc770b' },
                    { offset: 0.8, color: '#ffd604' },
                    { offset: 1, color: '#fff405' },
                  ],
                  global: false,
                },
                color: '#ffffff',
                show: true,
                fontSize: 16,
                textStyle: {
                  color: '#f1f011',
                },
              },
            },
            data: this.dataList,
          },
        ],
      }
      let that = this
      let mychart = this.$echarts.init(document.getElementById('regionCharts'))
      mychart.clear()
      mychart.setOption(option)
      this.$nextTick(() => {
        mychart.resize() // 这里是为了解决，tab刷新的时候，图表不刷新的问题。
      })
      mychart.on('click', function (params) {
        // that.initialSlide =
        if (params.data.index != null) {
          that.$refs.mySwiper.swiper.slideTo(params.data.index, 1000, false)
        }
      }) // 点击事件，此事件还可以用到柱状图等其他地图
    },
    prev() {
      this.changeDataList(this.$refs.mySwiper.swiper.realIndex - 1)
      this.$refs.mySwiper.swiper.slideTo(
        this.$refs.mySwiper.swiper.realIndex - 1,
        1000,
        false
      )
    },
    next() {
      this.changeDataList(this.$refs.mySwiper.swiper.realIndex + 1)
      this.$refs.mySwiper.swiper.slideTo(
        this.$refs.mySwiper.swiper.realIndex + 1,
        1000,
        false
      )
    },
    changeDataList(index) {
      if (index != null) {
        this.dataList.forEach((item, i) => {
          if (i === index) {
            this.dataList[i]['selected'] = true
          } else {
            this.dataList[i]['selected'] = false
          }
        })
        this.drawregionCharts()
      }
    },
  },
}
</script>

<style lang="less" scoped>
.home {
  background: #ffffff;
  .map-swiper {
    background: url('../assets/images/swiperbg1.png') no-repeat;
    background-size: 100% 100%;
    padding: 2.35rem 1.18rem 2.05rem 1.6rem;
    margin-top: -0.5rem;
    .map-content {
      padding-right: 50px;
    }
    .swiper-left {
      background: url('../assets/images/swiperbg.png') no-repeat;
      background-size: 100% 100%;
      text-align: left;
      width: 59.5rem;
      padding: 1.15rem 1.45rem 1.1rem 4.2rem;
    }
    img {
      width: 100%;
    }
    .swiper-left-text {
      text-indent: 2rem;
      height: 4rem;
      // height: calc(100% - 20px);
      color: #ffffff;
      line-height: 1.5rem;
      font-size: 0.95rem;
      font-weight: 400;
      font-size: 0.95rem;
      overflow-y: auto;
    }
    /* 设置滚动条的样式 */
    .swiper-left-text::-webkit-scrollbar {
      width: 5px; /*高宽分别对应横竖滚动条的尺寸*/

      height: 1px;
    }
    /* 滚动槽 */
    .swiper-left-text::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgb(255, 249, 249);

      border-radius: 5px;

      background: #494848;
    }
    /* 滚动条滑块 */
    .swiper-left-text::-webkit-scrollbar-thumb {
      border-radius: 5px;

      -webkit-box-shadow: inset 0 0 5px rgb(255, 255, 255);

      background: #fffbfb;
    }
    .swiper-left-text::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(255, 255, 255);
      // box-shadow: rgba(255, 0, 0, 0.4);
    }
    .swiper-center,
    .swiper-right {
      width: 12.75rem;
      padding: 0 0.8rem;
      min-width: 300px;
    }
    .swiper-button-prev {
      background-image: url(../assets/images/map_next.png) !important;
      width: 2rem !important;
      height: 1.95rem !important;
      background-size: 100% !important;
      left: 2rem !important;
      transform: rotate(180deg);
    }
    .swiper-button-next {
      background-image: url(../assets/images/map_next.png) !important;
      width: 2rem !important;
      height: 1.95rem !important;
      background-size: 100% !important;
      right: 2rem !important;
    }

    .swiper-button-disabled {
      opacity: 1;
      background: url(../assets/images/map_prev.png) no-repeat !important;
    }
    .swiper-button-disabled {
      transform: rotate(0deg);
    }
    .swiper-button-next.swiper-button-disabled {
      transform: rotate(180deg);
    }
  }
}
.map-modal {
  background: url(../assets/images/mapbg.png) no-repeat;
  background-size: cover;
  padding-bottom: 1.15rem;
  .map_left {
    width: 34.6rem;
    max-width: 100vw;
    height: 27.55rem;
    margin-right: 0.65rem;
    // min-width: 750px;
  }
  .map_center {
    width: 21.8rem;
    min-width: 375px;
    margin-right: 4.2rem;
    .text1_2 {
      text-align: left;
      margin: 0.5rem 0;
    }
    .qr-txt {
      margin-top: 0.8rem;
      display: block;
    }
    .qr-img {
      width: 7.1rem;
      height: 7.1rem;
      border-radius: 0.2rem;
      overflow: hidden;
    }
  }
  .map_right {
    width: 13rem;
    min-width: 220px;
  }
}
.margin-b {
  margin-bottom: 3.2rem;
}

@media screen and (min-width: 1981px) {
  .map-modal {
    padding: 1.5rem 10.5rem;
    .map_left {
      width: 64rem;
      height: 42rem;
    }
  }
  .map-swiper {
    padding: 1.5rem 10.5rem;
  }
  .map_center {
    margin-right: 4.2rem;
  }
}
@media screen and (min-width: 1600px) {
  .map-modal {
    justify-content: center;
    .map_left {
      margin-right: 3.5rem;
    }
  }
}
@media screen and (max-width: 1400px) {
  .map-swiper {
    padding-bottom: 3rem;
    .swiper-center,
    .swiper-right {
      margin-top: 1rem;
    }
    .map-content {
      flex-wrap: wrap;
    }
  }
  .map-modal {
    padding: 1.5rem 3.5rem;
    justify-content: center;
    .map_left {
      width: 64rem;
      height: 32rem;
    }

    .map_center {
      margin-right: 4.2rem;
    }
  }
  .margin-b {
    margin-bottom: 2.6rem !important;
  }
}

@media screen and (max-width: 768px) {
  .map-modal {
    text-align: center;
    justify-content: center;
    // padding-bottom: 20px;
    padding: 1.5rem 10.5rem;
    .map_left {
      width: 100vw;
      height: 500px;
      // min-width: 750px;
    }
    .map_center {
      margin-bottom: 2rem;
      align-items: center;
      margin-right: 0;
    }
    .map_right {
      text-align: center;
      text-align: -webkit-center;
    }
  }
}
.home-partner-module {
  width: 100vw;
  padding: 4rem 18.5vw 3.45rem;
  background: #ffffff;

  .home-partner-title {
    position: relative;
    height: 2.9rem;
    font-size: 2rem;
    font-weight: bold;
    color: #131414;
    line-height: 2.9rem;
    text-align: left;
    box-sizing: content-box;
    padding-bottom: 1rem;
  }
  .home-partner-title::after {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 3.2rem;
    height: 0.2rem;
    content: '';
    background: #4564f8;
    border-radius: 0rem 0rem 0rem 0rem;
  }
  .home-partner-main {
    width: 100%;
    margin-top: 1.25rem;
    .partner-content {
      width: 18.67%;
      margin-bottom: 1.2rem;
      margin-right: 1.6625%;
      border: 1px solid #e4e7ed;
      box-sizing: border-box;
    }
    .partner-content:nth-child(5n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1440px) {
  .home-partner-title {
    font-size: 1.6rem !important;
    height: 2.3rem !important;
    line-height: 2.3rem !important;
  }
  .home-partner-title::after {
    width: 2.6rem !important;
  }
}
@media only screen and (max-width: 1100px) {
  .map-modal {
    padding: 1.5rem 3.5rem;
    justify-content: center;
  }
  .margin-b {
    margin-bottom: 2rem !important;
  }
  .home-partner-module {
    padding: 2rem 10vw 1.45rem !important;
  }
  .home-partner-title {
    font-size: 1.2rem !important;
    height: 1.7rem !important;
    line-height: 1.7rem !important;
  }
  .home-partner-title::after {
    width: 2.2rem !important;
  }
  .home-partner-main {
    .partner-content {
      width: 23.8% !important;
      margin-bottom: 1.2rem;
      margin-right: 1.6% !important;
    }
    .partner-content:nth-child(4n) {
      margin-right: 0 !important;
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-partner-main {
    .partner-content {
      width: 48% !important;
      margin-bottom: 1.2rem;
      margin-right: 4% !important;
    }
    .partner-content:nth-child(2n) {
      margin-right: 0 !important;
    }
  }
}
</style>
