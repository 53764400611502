import request from '@/utils/request'

const path = `/system`

const postgraduateAPI = {
  // 获取学校列表
  getSchoolList (params) {
    return request({
      url: path + '/myTool/getSchoolList',
      method: 'GET',
      params
    })
  }

}

export default postgraduateAPI;
