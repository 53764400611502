<template>
  <div class="teacher-model">
    <div class="teacher-title">
      <p class="title-1">感同身受你的需要，</p>
      <p class="title-2">数百名大学老师、优秀学子带着使命、责任而来......</p>
      <p class="title-3">
        以他们的学习和成长经历，为你学习、考试、就业、发展等各方面提供帮助......
      </p>
    </div>
    <div class="teacher-main">
      <VueSlickCarousel :arrows="true" :dots="false" :autoplay="false">
        <div>
          <div class="main-item">
            <div class="main-right">
              <div class="main-right-title">
                通过科研训练，研究生应当具备四种能力：快速掌握新知识新技术的能力、与人交流传播思想的能力、发现问题与质疑的能力、提炼与总结共性的能力。
              </div>
              <div class="main-right-name">
                刘友波老师
                <div class="main-right-name-border"></div>
              </div>
              <div class="main-right-text">
                四川大学博士生导师，德国克劳斯塔尔大学访问学者 <br />
                四川大学、英国Brunel大学联合培养博士
              </div>
            </div>
            <div class="main-left">
              <img
                class="main-teacher-img"
                src="../assets/images/teacher_1.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <div class="main-item">
            <div class="main-right">
              <div class="main-right-title">
                材料是人类社会进步和科学技术发展的主要标志、里程碑、物质基础和技术先导。近年结合信息技术、大数据和人工智能技术的发展，面向未来需求，材料学科正在高质量发展，有着广阔的前景。
              </div>
              <div class="main-right-name">
                姚志浩老师
                <div class="main-right-name-border"></div>
              </div>
              <div class="main-right-text">
                北京科技大学博士生导师，材料学博士 <br />
                日本东北大学、美国哥伦比亚大学访问学者
              </div>
            </div>
            <div class="main-left">
              <img
                class="main-teacher-img"
                src="../assets/images/teacher_2.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <div class="main-item">
            <div class="main-right">
              <div class="main-right-title">
                作为国家技能形成体系的核心组件，职业教育不仅仅是生计教育，更是国家优势竞争力形成的制度基础之一。
              </div>
              <div class="main-right-name">
                王星老师
                <div class="main-right-name-border"></div>
              </div>
              <div class="main-right-text">
                南开大学教授、博士生导师<br />
                吉林大学、美国杜克大学联合培养博士
              </div>
            </div>
            <div class="main-left">
              <img
                class="main-teacher-img"
                src="../assets/images/teacher_3.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div>
          <div class="main-item">
            <div class="main-right">
              <div class="main-right-title">
                社会科学重视语言逻辑与数理逻辑的结合。学生在大学学习期间，要同时加强这两种逻辑思维的训练，才能有效提高自己分析问题、解决问题的能力。
              </div>
              <div class="main-right-name">
                路征老师
                <div class="main-right-name-border"></div>
              </div>
              <div class="main-right-text">
                四川大学教授、博士生导师 <br />
                四川大学博士、土耳其萨班哲大学博士后
              </div>
            </div>
            <div class="main-left">
              <img
                class="main-teacher-img"
                src="../assets/images/teacher_4.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Teacher',
  components: { VueSlickCarousel },
}
</script>

<style lang="less">
.slick-prev {
  left: inherit !important;
  right: 4.65rem !important;
}
.teacher-model {
  background-image: url(../assets/images/teacher_bg.png);
  padding: 2.3rem 0 3rem;
  .teacher-title {
    .title-1 {
      font-size: 2rem;
      color: #333333;
    }
    .title-2 {
      font-size: 2rem;
      color: #f26f13;
    }
    .title-3 {
      font-size: 0.9rem;
      color: #888888;
      margin-bottom: 1.8rem;
    }
  }
  .teacher-main {
    margin: 0 13.17% 0 11.74%;
    .slick-prev,
    .slick-next {
      width: 1.3rem;
      height: 1.4rem;
      z-index: 999;
    }
    .slick-prev:before,
    .slick-next:before {
      content: '';
    }
    .slick-prev {
      right: 7.6% !important;
      top: 89.7% !important;
      // bottom: 0.9rem !important;
      background-image: url(../assets/images/teacher_prev.jpg) !important;
    }
    .slick-next {
      right: 5% !important;
      top: 89.7% !important;
      // bottom: 0.9rem !important;
      background-image: url(../assets/images/teacher_next.jpg) !important;
    }
    .main-item {
      height: 22.3rem;
      display: inherit;
      .main-left {
        // position: absolute;
        width: 28.25rem;
        height: 18.45rem;
        padding-top: 0.5rem;
        background: #d1d8ff;
        border-radius: 0.5rem;
        margin-top: -20.35rem;

        .main-teacher-img {
          width: 28.25rem;
          height: 18.45rem;
          margin-left: 0.5rem;
          border-radius: 0.5rem;
        }
      }
      .main-right {
        // position: absolute;
        margin-left: 9.85rem;

        // width: 64.7rem;
        height: 22.3rem;
        background: url(../assets/images/teacher_bg2.png) no-repeat;
        background-size: 100% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: #ffffff;
        text-align: left;
        border-radius: 1rem;
        overflow: hidden;
        padding-left: 22.5rem;
        padding-right: 5.85rem;
        .main-right-title {
          font-size: 1.5rem;
          color: #fffbf7;
          line-height: 2rem;
          // width: 32.55rem;
        }
        .main-right-name {
          font-size: 1.5rem;
          margin-top: 2.2rem;
        }
        .main-right-name-border {
          width: 1.6rem;
          border-top: 0.35rem #ffffff solid;
          border-radius: 0.15rem;
          margin-top: 0.5rem;
        }
        .main-right-text {
          margin-top: 1.8rem;
          color: #d2d8fd;
        }
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  .teacher-model {
    .teacher-title {
      padding: 0 2rem;
      .title-1 {
        font-size: 1.8rem;
      }
      .title-2 {
        font-size: 1.8rem;
      }
      .title-3 {
        font-size: 0.8rem;
      }
    }

    .teacher-main {
      margin: 0px 8.17% 0 7.74%;
      .slick-prev,
      .slick-next {
        width: 1.3rem;
        height: 1.4rem;
        z-index: 999;
      }
      .slick-prev:before,
      .slick-next:before {
        content: '';
      }
    }
  }
}
@media only screen and (min-width: 1920px) {
  // .main-item {
  //   width: 80%;
  // }
  // .teacher-title {
  //   .teacher-main {
  //     .slick-prev {
  //       right: 10.6% !important;
  //     }
  //     .slick-next {
  //       right: 8% !important;
  //     }
  //   }
  // }
}

@media only screen and (max-width: 1400px) {
  .teacher-model {
    .teacher-title {
      .title-1 {
        font-size: 1.6rem;
      }
      .title-2 {
        font-size: 1.6rem;
      }
      .title-3 {
        font-size: 0.7rem;
      }
    }
    .main-item {
      padding-top: 38rem;
      .main-right {
        margin-top: -16.5rem !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        z-index: 1;
        height: auto !important;
        margin-left: 0 !important;
      }
      .main-left {
        margin-top: -35.45rem !important;
      }
    }
    .teacher-main {
      .slick-prev {
        right: 9% !important;
        top: 89.7% !important;
        // bottom: 0.9rem !important;
        // background-image: url(../assets/images/teacher_prev.jpg);
      }
      .slick-next {
        right: 5% !important;
        top: 89.7% !important;
        // bottom: 0.9rem !important;
        // background-image: url(../assets/images/teacher_next.jpg);
      }
    }
  }
}

@media only screen and (max-width: 1100px) {
  .teacher-model {
    .teacher-title {
      .title-1 {
        font-size: 1.4rem;
      }
      .title-2 {
        font-size: 1.4rem;
      }
      .title-3 {
        font-size: 0.7rem;
      }
    }
    .main-item {
      padding-top: 38rem;
      .main-right {
        margin-top: -16.5rem !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        z-index: 1;
        height: auto !important;
        margin-left: 0 !important;
      }
      .main-left {
        margin-top: -36.45rem !important;
      }
    }
    .teacher-main {
      .slick-prev {
        right: 12% !important;
        top: 89.7% !important;
        // bottom: 0.9rem !important;
        // background-image: url(../assets/images/teacher_prev.jpg);
      }
      .slick-next {
        right: 5% !important;
        top: 89.7% !important;
        // bottom: 0.9rem !important;
        // background-image: url(../assets/images/teacher_next.jpg);
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .teacher-model {
    .main-item {
      padding-top: 38rem;
      height: auto !important;
      padding-bottom: 10rem;

      padding-right: 0.5rem;
      .main-right {
        margin-top: -15.5rem !important;
        padding-right: 1rem !important;
        padding-left: 1rem !important;
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
        z-index: 1;
        height: auto !important;
        margin-left: 0 !important;
        .main-right-title {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          line-clamp: 3;
          -webkit-line-clamp: 3;
          -moz-line-clamp: 3;
          -ms-line-clamp: 3;
        }
      }

      .main-left {
        width: auto !important;
        margin-top: -43.45rem !important;
        position: relative;
        // padding-right: 1rem;
        img {
          position: absolute;
        }
      }
    }
  }
}
</style>
