import request from '@/utils/request'

const path = `/system`

const settledAPI = {
  // 添加教师
  addTeacher (params) {
    return request({
      url: path + '/teacherCenter/recommendTeacher',
      method: 'POST',
      data: params
    })
  },
  // 获取手机验证码
  checkPhoneCode (params) {
    return request({
      url: '/auth/applet/checkPhoneCode',
      method: 'POST',
      params
    })
  }
}

export default settledAPI
