import request from '@/utils/request'

const path = `/system`

const majorAPI = {
  // 获取顶级专业分类
  listByTopSpeciality (params) {
    return request({
      url: path + '/teacherCenter/listByTopSpeciality',
      method: 'get',
      params
    })
  },
  // 按专业找老师
  getTeachersListByMajor (params) {
    return request({
      url: path + '/teacherCenter/listBySpeciality',
      method: 'GET',
      params
    })
  },
  // 按学校找老师
  getTeachersListBySchool (params) {
    return request({
      url: path + '/teacherCenter/listBySchool',
      method: 'GET',
      params
    })
  },
  // 获取搜索建议（选专业）
  getDictDatal (params) {
    return request({
      url: path + '/dict/data/type/' + params,
      method: 'GET'
    })
  }
}

export default majorAPI
