import request from '@/utils/request'

const path = `/system`

const majorChooseSchoolAPI = {
  // 获取全部省份
  getAreaList (params) {
    return request({
      url: path + '/baseAreaTool/list',
      method: 'GET',
      params
    })
  },
  // 获取学校列表
  getSchoolList (params) {
    return request({
      // url: path + '/myTool/getSchoolList',
      url: path + '/teacherCenter/listBySchool',
      method: 'GET',
      params
    })
  },
  // 获取学校找老师 （通过查询学校名称）
  getTeacherListBySchoolName (params) {
    return request({
      url: path + '/teacherCenter/searchTeacherList',
      // url: path + '/teacherCenter/searchTeacherList',
      method: 'GET',
      params
    })
  },
  // 通过id获取老师
  getTeacherInfo (params) {
    return request({
      url: path + '/teacherCenter/getTeacherInfo',
      method: 'GET',
      params
    })
  }
}

export default majorChooseSchoolAPI
